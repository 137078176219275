import React from "react"

import Layout from "../components/layout"
import { Link } from "gatsby"

import Head from "../components/head"
import * as PrivacyStyles from "./privacy.module.scss"

const Privacy = () => {
  return(
    <Layout>
      <Head title="Privacy Policy and Term of services" description="Privacy Policy and Term of services for using our website" pathname="privacy" />
        <h2 className={PrivacyStyles.heading}>Privacy Information</h2>
        <div class="dashed-line"></div>
        <h3>Our Privacy Policy and Terms Of services:</h3>
        <ul>
            <li>"We or us" refers to KingsCourtStudio</li>
            <li>"Our Website" refers to <a href="https://thewarwillwin.com">https://thewarwillwin.com</a></li>
            <li>"Our Games" refers to games made by KingsCourtStudio</li>
            <li>"You" refers to the person using our website</li>
        </ul>
        <h3>Our Privacy Policy and Terms Of services:</h3>
        <ul>
            <li>We use <a class="privacy-links" href="https://analytics.google.com" target="_blank" rel="noreferrer">Google Analytics</a> to track visits to our website and to improve our website accordingly.</li>
            <li>We also use <a class="privacy-links" href="https://www.netlify.com/" target="_blank" rel="noreferrer">Netlify</a> forms to collect quick messages from you, if you used our quick messaging system on our website.</li>
            <li>By using our website you agree to the before-mentioned companies terms of services and privacy policies. If you are unaware of the before-mentioned company's terms of services and privacy policies, please check their respective websites and find out or discontinue using our website.</li>
            <li>All the files, assets and logos on our website and our <Link to="/presskit">PressKit</Link> are provided for being used in review or by others to mention us or write reviews for our game and not provided to be modified or sold.</li>
        </ul>
    </Layout>
  )
}

export default Privacy